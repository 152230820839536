<template>
  <div class="qrcode-info">
    <van-swipe :autoplay="5000">
      <van-swipe-item v-for="(image, index) in images" :key="index">
        <img v-lazy="image.url" style="height: 200px; width: 100%" />
      </van-swipe-item>
    </van-swipe>

    <van-list>
      <van-cell
        v-for="(item, index) in list"
        :key="index"
        :title="item.label"
        :value="item.value"
        :is-link="item.is_link"
        @click="selectItem(item)"
      />
    </van-list>

    <van-dialog style="height: 80%" v-model="showDialog" title="质检信息">
      <div
        v-html="zj_content"
        class="cu-dialog"
        @click="imageChanged($event)"
      ></div>
    </van-dialog>

    <div v-if="qrcode.biz_tel_desc" class="contact" @click="callMe()">
      {{ qrcode.biz_tel_desc || '@成都溯源：19302871101' }}
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem, Lazyload } from "vant";

import { List, Cell, Dialog, ImagePreview, Button } from "vant";

import "vant/es/dialog/style";

Vue.use(List);
Vue.use(Cell);

Vue.use(Dialog);
Vue.use(ImagePreview);

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Button);

export default {
  name: "qrcode",
  data() {
    return {
      qrcode: {},
      images: [],
      list: [],
      showDialog: false,
      zj_content: null,
    };
  },
  created() {
    document.title = "溯源信息";
  },
  mounted() {
    // console.log(this.$route.params.sku);
    // const sku =
    document.title = "溯源信息";

    this.loadData();
  },
  methods: {
    callMe() {
      window.open(`tel:${this.qrcode.biz_tel || '19302871101'}`);
    },
    imageChanged(ev) {
      // console.log(ev);
      if (ev.target.nodeName == "IMG") {
        console.log(12333);
        ImagePreview({
          images: [ev.target.currentSrc],
          showIndex: false,
          loop: false,
          closeable: true
        });
      } else {
        return;
      }
    },
    selectItem(item) {
      if (!item.is_link) return;

      this.showDialog = true;

      this.zj_content = this.qrcode.qrcode_info.zj_content;
      // Dialog.alert({
      //   title: "质检信息",
      //   allowHtml: true,
      //   messageAlign: "left",
      //   className: "cu-dialog",
      //   message: this.qrcode.qrcode_info.zj_content,
      // }).then(() => {
      //   // on close
      //   console.log(123);
      // });
    },
    loadData() {
      const sku = this.$route.params.sku;
      this.$get("qrcode/prod/" + sku, null, (res) => {
        // console.log(res);
        if (res.code === 0) {
          this.qrcode = res.data || {};
          if (this.qrcode.qrcode_info) {
            this.images = this.qrcode.qrcode_info.prod_image_assets || [];
          }

          const temp = [];
          if (this.qrcode.qrcode_info) {
            const obj = this.qrcode.qrcode_info;
            const arr = this.qrcode.form_data_desc || obj.form_data_desc || [];
            if (arr.length > 0) {
              let i = 0;
              arr.forEach((ele) => {
                if (i == 2) {
                  // temp.push({
                  //   label: "扫码次数",
                  //   value: this.qrcode.view_count + "次",
                  // });
                  temp.push({
                    label: "单元识别号",
                    value: this.qrcode.sku,
                  });
                }
                i++;
                temp.push(ele);
              });
            } else {
              if (obj.prod_name) {
                temp.push({
                  label: "品种名称",
                  value: obj.prod_name,
                });
              }
              if (obj.prod_name) {
                temp.push({
                  label: "登记证持有人",
                  value: obj.comp_name,
                });
              }
              if (obj.prod_lic_no) {
                temp.push({
                  label: "生产许可证编号",
                  value: obj.prod_lic_no,
                });
              }

              if (obj.prod_st_no) {
                temp.push({
                  label: "产品标准号",
                  value: obj.prod_st_no,
                });
              }

              if (this.qrcode.view_count) {
                temp.push({
                  label: "扫码次数",
                  value: this.qrcode.view_count + "次",
                });
              }

              if (this.qrcode.sku) {
                temp.push({
                  label: "单元识别号",
                  value: this.qrcode.sku,
                });
              }

              if (obj.farm_lic_no) {
                temp.push({
                  label: "登记证号",
                  value: obj.farm_lic_no,
                });
              }

              if (obj.factory_name) {
                temp.push({
                  label: "生产企业",
                  value: obj.factory_name,
                });
              }

              if (obj.factory_addr) {
                temp.push({
                  label: "生产地址",
                  value: obj.factory_addr,
                });
              }

              if (obj.save_desc) {
                temp.push({
                  label: "储藏方法",
                  value: obj.save_desc,
                });
              }

              if (obj.exp_date) {
                temp.push({
                  label: "保质期",
                  value: obj.exp_date,
                });
              }

              if (obj.prod_batch_no) {
                temp.push({
                  label: "生产批次",
                  value: obj.prod_batch_no,
                });
              }

              if (obj.prod_attr) {
                temp.push({
                  label: "商品规格",
                  value: obj.prod_attr,
                });
              }
            }
          }

          this.list = temp;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
<style>
.qrcode-info {
  padding-bottom: 30px;
}
.cu-dialog {
  padding: 15px;
  font-size: 14px;
  color: #333;
  line-height: 1.2em;
  box-sizing: border-box;
}

.van-dialog__content {
  box-sizing: border-box;
  max-height: calc(100% - 100px);
  overflow-y: auto;
}
.cu-dialog img {
  max-width: 100% !important;
}
.contact {
  cursor: pointer;
  /* pointer-events: ; */
  padding: 30px;
  text-align: center;
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>